import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'gt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() color: 'primary' | 'secondary' | 'danger' | 'clean' | null = 'primary';
  @Input() size: 'm' | 'xl' = 'm';
  @Input() expand: 'block' | 'full' | 'max' = 'block';
  @Input() disabled = false;
  @Input() onlyIcon: 'onlyIcon' | null = null;
  @Input() iconPosition: 'top' | 'left' = 'left';
}
