import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: 'https://f4c98d20b3e5a6ab7f434f4f21ac5644@o4506876475539456.ingest.us.sentry.io/4508261713575936',
  enabled: environment.production,
  environment: environment.production ? 'pro' : 'dev',
  release: 'web@' + environment.version,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', 'capacitor'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
