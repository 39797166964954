import { Inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  collectionGroup,
  CollectionReference,
  doc,
  docData,
  DocumentData,
  Firestore,
  getDoc,
  limit,
  Query,
  query,
  QueryConstraint,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { ENVIRONMENT } from '../core.constants';
import { deleteDoc, DocumentReference, QueryCompositeFilterConstraint } from '@firebase/firestore';
import { forkJoin, Observable, map, tap, first, filter } from 'rxjs';
import { Entity } from '../models/common.model';
import { convertTimestampsToDates } from '@core/utils/convertTimestamps';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private documentsReaded = 0;

  constructor(
    private firetore: Firestore,
    @Inject(ENVIRONMENT) private environment: any,
  ) {}

  getCollectionRef(path: string): CollectionReference<DocumentData> {
    return collection(this.firetore, path);
  }

  generateId(path: string): string {
    const col = this.getCollectionRef(path);
    return doc(col).id;
  }

  getDocumentRef<T = DocumentData>(path: string, id: string): DocumentReference<T> {
    const col = this.getCollectionRef(path);
    return doc(col, id) as DocumentReference<T>;
  }

  getDocument<T extends Entity>(collection: string, id: string): Observable<T> {
    const doc = this.getDocumentRef(collection, id) as DocumentReference<T>;
    return docData<T>(doc, {
      idField: 'id',
    }).pipe(
      tap((res) => this.countDatabaseReads(1, [doc.path], res)),
      map((res: T | undefined) => {
        if (!res) {
          throw new Error(`Document "${id}" does not exist on "${collection}"`);
        }
        if (res.deleted) {
          throw new Error(`Document "${id}" from "${collection}" is deleted.`);
        }
        return convertTimestampsToDates(res);
      }),
    );
  }

  findDocument<T extends Entity>(collection: string, ...queryConstraints: QueryConstraint[]): Observable<T | null> {
    return this.listDocuments<T>(collection, ...queryConstraints, limit(1)).pipe(map((d) => d[0] ?? null));
  }

  getAllDocuments<T extends Entity>(collection: string, ids: string[]): Observable<T[]> {
    return forkJoin(ids.map((id) => this.getDocument<T>(collection, id)));
  }

  listDocuments<T extends Entity>(
    path: string,
    ...queryConstraints: (QueryConstraint | QueryCompositeFilterConstraint)[]
  ): Observable<T[]> {
    const collection = this.getCollectionRef(path);
    const q = query<T, DocumentData>(collection as unknown as Query<T>, ...(queryConstraints as QueryConstraint[]));
    return collectionData<T>(q, {
      idField: 'id',
    }).pipe(
      tap((d) => this.countDatabaseReads(d.length, [path, ...queryConstraints.map((el) => el)], d)),
      map((s) => s.filter((d) => d.deleted !== true).map((el) => convertTimestampsToDates(el))),
    );
  }

  listDocumentsWithId<T extends Entity>(path: string, ...queryConstraints: QueryConstraint[]): Observable<T[]> {
    const collection = this.getCollectionRef(path);
    const q = query<T, DocumentData>(collection as unknown as Query<T>, ...queryConstraints);
    return collectionData<T>(q, {
      idField: 'id',
    }).pipe(
      tap((d) => this.countDatabaseReads(d.length, [path, ...queryConstraints.map((el) => el.type)], d)),
      map((s) => s.filter((d) => d.deleted !== true).map((el) => convertTimestampsToDates(el))),
    );
  }

  listDocumentsGroup<T extends Entity>(groupId: string, ...queryConstraints: QueryConstraint[]): Observable<T[]> {
    const q = query<T, DocumentData>(collectionGroup(this.firetore, groupId) as Query<T>, ...queryConstraints);
    return collectionData<T>(q, {
      idField: 'id',
    }).pipe(
      tap((d) => this.countDatabaseReads(d.length, [groupId, ...queryConstraints.map((el) => el.type)], d)),
      map((s) => s.filter((d) => d.deleted !== true).map((el) => convertTimestampsToDates(el))),
    );
  }

  exists(collection: string, id: string): Promise<boolean> {
    const doc = this.getDocumentRef(collection, id);
    this.countDatabaseReads(1, ['exists', id]);
    return getDoc(doc)
      .then((doc) => doc.exists())
      .catch(() => false);
  }

  async create<T>(collection: string, data: Partial<T>): Promise<string> {
    const dbData = {
      id: this.generateId(collection),
      createdAt: Date.now(),
      updatedAt: Date.now(),
      ...data,
    };
    const col = this.getCollectionRef(collection);
    const document = doc(col, dbData.id);
    await setDoc<DocumentData, DocumentData>(document, dbData);
    return dbData.id;
  }

  async createOrUpdate<T extends Entity>(collection: string, id: string, data: Partial<T>): Promise<void> {
    if (data.id && data.id !== id) {
      throw new Error('Document Id mismatch from data id');
    }
    const dbData = data;
    const exists = await this.exists(collection, id);
    if (!exists) {
      dbData.createdAt = new Date();
    }
    dbData.id = id;
    dbData.updatedAt = new Date();
    const doc = this.getDocumentRef(collection, id);
    return setDoc<DocumentData, DocumentData>(doc, dbData, { merge: true });
  }

  async update<T extends Entity>(collection: string, data: Partial<T>): Promise<void> {
    const dbData = data;
    const id = data.id;
    if (!id) throw new Error('Document data does not contain ID');
    dbData.updatedAt = new Date();
    const doc = this.getDocumentRef(collection, id);
    return updateDoc<DocumentData, DocumentData>(doc, dbData);
  }

  async delete(collection: string, id: string): Promise<void> {
    return this.update(collection, { id, deleted: true });
  }

  async deleteForever(collection: string, id: string): Promise<void> {
    const doc = this.getDocumentRef(collection, id);
    return deleteDoc(doc);
  }

  private countDatabaseReads(length: number, query: any[] = [], data?: any) {
    if (this.environment.production) return;
    const newCount = this.documentsReaded + length;
    console.debug(
      '%c[FirestoreService]',
      'background: #F9CB36; color: #000',
      `Document reads: ${this.documentsReaded} (+${length}) => ${newCount}`,
      query,
      data,
    );
    this.documentsReaded = newCount;
  }
}
