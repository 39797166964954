import { Component, inject, isDevMode } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'gt-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  translocoService = inject(TranslocoService);
  public isDev = isDevMode();

  constructor() {
    window.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'queryParams') {
        this.translocoService.setActiveLang(event.data.language ?? 'es');
      }
    });
  }
}
