import { Timestamp } from '@firebase/firestore';

export function convertTimestampsToDates(obj: any) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (value instanceof Timestamp) {
        obj[key] = value.toDate();
      } else if (typeof value === 'object') {
        convertTimestampsToDates(value);
      }
    }
  }

  return obj;
}
