import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@core/components/loading/loading.module';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonComponent } from './components/button/button.component';
import { HeaderComponent } from './components/header/header.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { EditionRatesPipe } from './pipes/edition-rates.pipe';
import { ImageLoaderUrl } from './pipes/message-image-loader.pipe';
import { CountryFlagPipe } from './pipes/country-flag.pipe';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { ImageDocUrlPipe } from './pipes/image-doc.pipe';

const COMPONENTS = [TabsComponent, ButtonComponent, HeaderComponent, PlaceholderComponent];
const PIPES = [EditionRatesPipe, ImageLoaderUrl, CountryFlagPipe, ImageDocUrlPipe]
@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule, RouterModule, LoadingModule],
  declarations: [...PIPES, ...COMPONENTS],
  exports: [...PIPES, ...COMPONENTS],
})
export class SharedModule {}
