export const environment = {
  production: true,
  appType: 'maroc',
  platform: 'web',
  version: '0.0.15',
  defaultLang: 'es',
};

export const firebaseConfig = {
  apiKey: 'AIzaSyBEwxAI5ZuQTG0VXwomCBLYIkJ5QsCWBHg',
  authDomain: 'grandtour-pro.firebaseapp.com',
  projectId: 'grandtour-pro',
  storageBucket: 'grandtour-pro.appspot.com',
  messagingSenderId: '783574732594',
  appId: '1:783574732594:web:1040b97f95e52d973f112b',
};
