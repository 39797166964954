import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LottieModule } from 'ngx-lottie';
import { LoadingComponent } from './loading.component';

@NgModule({
  imports: [CommonModule, IonicModule, LottieModule],
  exports: [],
  declarations: [LoadingComponent],
  providers: [],
})
export class LoadingModule {}
