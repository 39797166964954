import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '@core/models/common.model';
import { Rate } from '@core/models/edition.model';

@Pipe({
  name: 'editionRates',
})
export class EditionRatesPipe implements PipeTransform {
  transform(rates: Rate[], category: Category): Rate[] {
    return rates.filter((rate) => rate.categories.includes(category));
  }
}
